<template>
  <div class="news">
    <img class="loading" v-if="loading == true" src="../../../static/common/loading.png" alt="">
    <div v-loading="loading" class="newsBox" v-for="item in newData" :key="item.ID">
      <img class="titlePic" :src="item.TitlePic" alt="">
      <ul>
        <li @click="clickNewsDetails(item.ID)">{{item.Title}}</li>
        <li>{{item.Synopsis}}</li>
        <li>
          <p>
            <img src="../../../static/market/news/user.png" alt="">
            <span>{{item.WriterName}}</span>
          </p>
          <p>
            <img src="../../../static/market/news/eyes.png" alt="">
            <span>{{Math.ceil(Math.random()*1000)}}</span>
          </p>
          <p>
            <img src="../../../static/market/news/clock.png" alt="">
            <span>{{item.NewsTime}}</span>
          </p>
        </li>
      </ul>
    </div>
    <button @click="moreNews">
      <span>更多</span>
      <img src="../../../static/market/more.png" alt="">
    </button>
  </div>
</template>

<script>
  import {newList} from '../../../api/info'
export default {
  name:'news',
  data() {
    return {
      newData:[],
      // 接口参数
      attr:{
        pageIndex:1,
        pageRow:5,
      },
      loading:true,
    }
  },
  created(){
    // 初始化数据
    this.init(this.attr,0);
  },
  methods:{
    // 接口调用
    init(attr,key){
      newList(attr).then(res=>{
        if(res.ok == 1){
          if(key == 1){
            this.newData = [...this.newData,...res.data];
            console.log(this.newData);
          }else{
            this.newData = res.data;
            this.loading = false;
          }
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 获取更多新闻 
    moreNews(){
      if(this.attr.pageRow == 5){
        this.attr.pageIndex = 1;
        this.attr.pageRow = 10;
        this.init(this.attr,0);
      }else{
        this.attr.pageIndex++;
        this.init(this.attr,1);
      }
    },
    // 跳转到新闻详情页
    clickNewsDetails(attr){
      let routeData = this.$router.resolve({ 
        name: "newsDetails",
          query: { 
            id:attr
          }
      });
      window.open(routeData.href, '_blank'); 
    }
  }
}
</script>

<style lang="less" scoped>
  .news{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loading{
      margin: 0 auto 30px;
      width: 50px;
    }
    .newsBox{
      width: 100%;
      height: 305px;
      box-sizing: border-box;
      padding: 50px 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid #c2c2c2;
      .titlePic{
        width: 330px;
        height: 230px;
        object-fit: cover;
        margin-right: 50px;
      }
      ul{
        width: 72.5%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0;
        li{
          width: 100%;
          p{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 20px;
            img{
              margin-right: 8px;
            }
          }
        }
        li:nth-child(1){
          font-size: 22px;
          font-weight: 540;
          color: #676767;
          cursor: pointer;
        }
        li:nth-child(2){
          color: #666666;
          line-height: 26px;
        }
        li:nth-child(3){
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: #666666;
        }
      }
    }
    button{
      width: 195px;
      height: 60px;
      border-radius: 5px;
      background-color: #db3d4c;
      border: none;
      margin: 72px auto 110px; 
      cursor: pointer;
      span{
        color: #ffffff;
        margin-right: 5px;
      }
      img{
        width: 10px;
        height: auto;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .news{
      width: 100%;
      .loading{
        margin: 0 auto 30px;
        width: 30px;
      }
      .newsBox{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #c2c2c2;
        .titlePic{
          width: 90%;
          margin-right: 0px;
          border-radius: 5px;
        }
        ul{
          width: 90%;
          margin: 0 auto;
          box-sizing: border-box;
          padding-top: 30px;
          li:nth-child(1){
            font-size: 16px;
            font-weight: 600;
          }
          li:nth-child(2){
            font-size: 13px;
            line-height: 20px;
            margin: 20px auto 10px;
          }
          li:nth-child(3){
            p{
              margin-left: 10px;
              span{
                font-size: 12px;
              }
              img{
                width: 18px;
                height: auto;
                margin-right: 3px;
              }
            }
          }
        }
      }
      button{
        width: 125px;
        height: 40px;
        border-radius: 5px;
        background-color: #db3d4c;
        border: none;
        cursor: pointer;
        color: #ffffff;
        margin: 20px 0;
        font-size: 14px;
      }
    }
  }
</style>